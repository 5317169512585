export default [
  {
    resource: 'Auth',
    action: 'Read',
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Device Owners',
    icon: 'UserCheckIcon',
    children: [
      {
        resource: 'Owner',
        action: 'Read',
        title: 'List Owners',
        route: 'owners',
      },
      {
        resource: 'Owner',
        action: 'Read',
        title: 'Owner Activities',
        route: 'owner-activities',
      },
    ],
  },
  {
    resource: 'Device',
    action: 'Read',
    title: 'Devices',
    route: 'devices',
    icon: 'SmartphoneIcon',
  },
  {
    resource: 'Machine',
    action: 'Read',
    title: 'Machines',
    route: 'machines',
    icon: 'MonitorIcon',
  },
  {
    resource: 'Group',
    action: 'Read',
    title: 'Groups',
    route: 'groups',
    icon: 'CheckCircleIcon',
  },
  {
    resource: 'Group',
    action: 'Read',
    title: 'Enrollment Group',
    route: 'enrollment',
    icon: 'CloudIcon',
  },
  {
    resource: 'Network',
    action: 'Read',
    title: 'Networks',
    route: 'networks',
    icon: 'GlobeIcon',
  },
  {
    resource: 'Scribe',
    action: 'Read',
    title: 'Purple Scribe',
    route: 'purple-scribe',
    icon: 'PenToolIcon',
  },
  {
    resource: 'PurpleDocs',
    action: 'Read',
    title: 'Purple Docs',
    route: 'purple-docs-documents',
    icon: 'BookOpenIcon',
  },
  {
    resource: 'PDFForm',
    action: 'Read',
    title: 'PDF Forms',
    route: 'pdf-forms',
    icon: 'FileTextIcon',
  },
  {
    resource: 'CalendarEvents',
    action: 'Read',
    title: 'Calendar',
    route: 'calendar',
    icon: 'CalendarIcon',
  },
  {
    resource: 'KioskAnnouncements',
    action: 'Read',
    title: 'Announcements',
    route: 'announcements',
    icon: 'RadioIcon',
  },
  {
    resource: 'PurplePlay',
    action: 'Read',
    title: 'Purple Play',
    route: 'purple-play',
    icon: 'YoutubeIcon',
  },
  {
    resource: 'Locations',
    action: 'Read',
    title: 'Locations',
    route: 'locations',
    icon: 'GlobeIcon',
  },
  {
    title: 'Possessions',
    icon: 'ArchiveIcon',
    children: [
      {
        resource: 'Possessions',
        action: 'Read',
        title: 'List Possessions',
        route: 'possessions',
      },
      {
        resource: 'PossessionCategories',
        action: 'Read',
        title: 'Categories',
        route: 'possession-categories',
      },
    ],
  },
  {
    resource: 'Tracker',
    action: 'Read',
    title: 'Tracker',
    route: 'tracker',
    icon: 'FlagIcon',
  },
  {
    resource: 'User',
    action: 'Read',
    title: 'System Users',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    resource: 'Role',
    action: 'Read',
    title: 'Roles',
    route: 'roles',
    icon: 'LockIcon',
  },
  {
    resource: 'Activity',
    action: 'Read',
    title: 'Staff Activities',
    route: 'activities',
    icon: 'ActivityIcon',
  },
  {
    resource: 'Keys',
    action: 'Read',
    title: 'Keys',
    route: 'keys',
    icon: 'KeyIcon',
  },
  {
    resource: 'PurpleVisits',
    action: 'Read',
    title: 'Purple Visits',
    href: 'https://v2.purplevisitsportal.com',
    icon: 'VideoIcon',
  },
  {
    resource: 'PurplePost',
    action: 'Read',
    title: 'Purple Post',
    href: 'https://www.purple-post.com',
    icon: 'MailIcon',
  },
  {
    resource: 'TheHub',
    action: 'Read',
    title: 'theHub',
    href: 'https://jadmin.purplemdm.com',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'Admin',
    icon: 'MenuIcon',
    resource: 'All',
    action: 'Manage',
    children: [
      {
        resource: 'All',
        action: 'Manage',
        title: 'Enterprises',
        route: 'enterprises',
        icon: 'HardDriveIcon',
      },
      {
        resource: 'All',
        action: 'Manage',
        title: 'All Devices',
        route: 'admin-devices',
        icon: 'TabletIcon',
      },
      {
        resource: 'All',
        action: 'Manage',
        title: 'Purple Accounts',
        route: 'purple-accounts',
        icon: 'UserPlusIcon',
      },
      {
        title: 'Kiosk',
        icon: 'LayoutIcon',
        children: [
          {
            resource: 'All',
            action: 'Manage',
            title: 'Sites',
            route: 'kiosk-sites',
          },
          {
            resource: 'All',
            action: 'Manage',
            title: 'Blacklist',
            route: 'kiosk-blacklist',
          },
          {
            resource: 'All',
            action: 'Manage',
            title: 'Tags',
            route: 'kiosk-tags',
          },
          {
            resource: 'All',
            action: 'Manage',
            title: 'Channels',
            route: 'kiosk-channels',
          },
          {
            resource: 'All',
            action: 'Manage',
            title: 'News Feeds',
            route: 'kiosk-news-feeds',
          },
          {
            resource: 'All',
            action: 'Manage',
            title: 'Global CSS',
            route: 'kiosk-global-css',
          },
        ],
      },
      {
        title: 'Purple Docs',
        icon: 'FileTextIcon',
        children: [
          {
            resource: 'All',
            action: 'Manage',
            title: 'Categories',
            route: 'purple-docs-template-categories',
          },
          {
            resource: 'All',
            action: 'Manage',
            title: 'Templates',
            route: 'purple-docs-templates',
          },
        ],
      },
      {
        resource: 'All',
        action: 'Manage',
        title: 'EAPI Policies',
        route: 'eapi-policies',
        icon: 'BookOpenIcon',
      },
      {
        title: 'Billing',
        icon: 'ClipboardIcon',
        children: [
          {
            resource: 'All',
            action: 'Manage',
            title: 'Create Bill',
            route: 'unbilled-enterprises',
          },
          {
            resource: 'All',
            action: 'Manage',
            title: 'View Bills',
            route: 'view-bills',
          },
        ],
      },
    ],
  },

]
